// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react'

function TradingViewWidget({ pair }) {
  const container = useRef()

  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js'
    script.type = 'text/javascript'
    script.async = false
    script.id = pair
    script.innerHTML = `
        {
    "symbol": "${pair}",
  "width": "100%",
  "height": "100%",
  "locale": "en",
  "dateRange": "12M",
  "colorTheme": "dark",
  "isTransparent": true,
  "autosize": true,
  "largeChartUrl": ""
        }`

    if (document.querySelectorAll(`script[id=${pair}]`).length < 1) {
      container.current.appendChild(script)
    }
    // return () => container.current.removeChild(script)
  }, [])

  return (
    <>
      <div className="tradingview-widget-container" ref={container}>
        <div className="tradingview-widget-container__widget p-0"></div>
        <div className="tradingview-widget-copyright">
          <a
            href="https://www.tradingview.com/"
            rel="noopener nofollow"
            target="_blank"
          >
            <span className="blue-text"></span>
          </a>
        </div>
      </div>
    </>
  )
}

export default memo(TradingViewWidget)
