import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useState, useEffect } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BiCopyAlt } from 'react-icons/bi'
import Swal from 'sweetalert2'
export default function Newdeposit({
  coins,
  user,
  curData,
  mplans,
  getRates,
  logout,
}) {
  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()
  const [navState, setNavState] = useState('hide')
  const [addr, setAddr] = useState('')
  const [tid, setTid] = useState(0)
  const [pop, setPop] = useState('')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  const closeTrnx = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=closetrnx'}`,
      withCredentials: false,
      data: {
        tid: tid,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        if (response.data.type == 'success') {
          return navigate('/office/deposit')
        } else {
          Swal.fire(response.data.msg)
        }
      })
      .catch((error) => console.error(error))
  }
  const createTrnx = () => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/?r=createtrnx`,
      data: {
        type: searchParams?.get('cur'),
        plan: searchParams?.get('title'),
        amount: searchParams?.get('amount'),
        coin: searchParams?.get('coin'),
      },
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        if (response?.data?.msg) {
          Swal.fire(response?.data?.msg)
        } else if (response?.data?.address) {
          setAddr(response.data.address)
          setTid(response.data.tid)
        }
      })
      .catch((error) => console.error(error))
  }
  const Copy = (a) => {
    navigator.clipboard.writeText(a)
    Swal.fire('WALLET COPIED', a, 'success')
  }
  useEffect(() => {
    getRates()
    createTrnx()
  }, [])
  const uploadPop = (e) => {
    e.preventDefault()
    var formData = new FormData(e.target)
    axios
      .post(`${process.env.REACT_APP_SERVER}/?r=trnxpop&tid=${tid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response?.data?.msg) {
          Swal.fire(
            'PAYMENT RECEIPT',
            response?.data?.msg,
            response?.data?.type,
          )
        }
        if (response?.data?.pop) {
          setPop(response?.data?.pop)
        }
      })
      .catch((error) => console.error(error))
  }
  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 w-full">
        <div className="flex flex-2 gap-1 px-3 mt-5">
          <Link to="/office/dashboard">Dashboard</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/deposit">Deposit</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/mining/deposit">Mining</Link>
          <Link to="/office/mining/deposit/new">New</Link>
        </div>
        <h2 className="text-2xl mx-auto text-center">NEW DEPOSIT</h2>
        <div className="flex text-slate-100 mx-auto lg:w-[60%] w-full mx-auto px-3 py-10">
          <div className="px-10 w-full flex flex-col bg-slate-800  py-6 border-2 border-slate-400 justify-center">
            {addr == '' && (
              <p className="bg-green-300 text-white p-3">
                No Active {searchParams?.get('cur')} Wallet.
              </p>
            )}
            {addr !== '' &&
              coins?.filter(
                (c) =>
                  c?.symbol.toLowerCase() ===
                  searchParams?.get('cur').toLowerCase(),
              ).length > 0 && (
                <div className="flex flex-col py-3 text-center border-1 border-slate-400">
                  <span className="text-lg font-light my-2">
                    SEND{' '}
                    <span className="font-bold">
                      {Number(
                        searchParams?.get('amount') *
                          coins?.filter(
                            (c) =>
                              c?.symbol.toLowerCase() ===
                              searchParams?.get('cur').toLowerCase(),
                          )[0].rate,
                      ).toPrecision(6)}
                    </span>{' '}
                    {searchParams?.get('cur')} TO THE WALLET ADDRESS BELOW OR
                    SCAN THE QR CODE WITH YOUR WALLET APP
                  </span>
                </div>
              )}
            {addr !== '' && (
              <>
                <div className="flex justify-end mr-[-5px] text-red-500 hover:text-red-300">
                  {' '}
                  {pop == '' && (
                    <span
                      className="cursor-pointer"
                      onClick={(e) =>
                        Swal.fire({
                          title: 'Are you sure you want to Cancel Transaction',
                          showCloseButton: true,
                          showCancelButton: true,
                          confirmButtonText: 'Cancel',
                          cancelButtonText:
                            "<span class='text-green-400'>Back</span>",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            closeTrnx(e)
                          }
                        })
                      }
                    >
                      Cancel Transcation
                    </span>
                  )}
                  {pop !== '' && <Link to="/office/deposit#history">Back</Link>}
                </div>{' '}
                <p className="mx-auto">Pay With</p>
                <p className="bg-blue-300 text-white p-3 text-center flex gap-3 justify-center">
                  <span>{addr}</span>
                  <BiCopyAlt
                    className="text-xl cur"
                    title="Click to copy"
                    onClick={() => Copy(addr)}
                  />
                </p>
                <div className="flex flex-col py-3 text-center gap-2">
                  <div className="py-5 px-2 lg:w-[250px] w-[160px] text-center mx-auto">
                    <img
                      alt={addr}
                      src={`${process.env.REACT_APP_SERVER}/?r=qr&addr=${addr}`}
                      className="w-full h-full"
                    />
                  </div>
                  {pop === '' && (
                    <form
                      method="post"
                      encType="multipart/form-data"
                      onSubmit={(e) => uploadPop(e)}
                    >
                      <input
                        type="file"
                        name="pop"
                        className="border-2 border-slate-300 rounded-lg p-1 my-2"
                      />
                      <button
                        type="submit"
                        className="py-5 px-2 w-full border-2 border-slate-100 bg-slate-600 text-center "
                      >
                        UPLOAD PAYMENT RECEIPT
                      </button>
                    </form>
                  )}
                  {pop !== '' && (
                    <>
                      <p className="bg-green-400 p-2 mx-auto">
                        PAYMENT PENDING APPROVAL.YOU WILL BE NOTIFIED ONCE
                        APPROVED.
                      </p>
                      <img
                        alt="pop"
                        src={`${process.env.REACT_APP_SERVER.replace(
                          '/app.php',
                          '',
                        )}/${pop}`}
                        className="w-full h-full"
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
