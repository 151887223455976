import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Tabs } from 'flowbite-react'

export default function Mdeposit({
  coins,
  user,
  curData,
  mplans,
  getmPlans,
  logout,
}) {
  const [navState, setNavState] = useState('hide')
  const [cusamt, setCusamt] = useState(300)
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  useEffect(() => {
    getmPlans()
  }, [])

  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} user={user} logout={logout} />
      <div>
        <Unavbar navState={navState} toggleNav={toggleNav} logout={logout} />
      </div>
      <div className="py-1 w-full">
        <div className="flex flex-2 gap-1 px-3 mt-5">
          <Link to="/office/dashboard">Dashboard</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/deposit">Deposit</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/mining/deposit">Mining</Link>
        </div>
        <h2 className="text-2xl mx-auto text-center">DEPOSIT</h2>
        <div className="flex text-slate-100 mx-auto w-full">
          <Tabs
            aria-label="Default tabs"
            variant="underline"
            className="w-full flex justify-center"
          >
            {coins.map((c, k) => (
              <Tabs.Item active title={c.symbol} key={k}>
                <div className="w-full grid lg:grid-cols-4 grid-cols-1">
                  {mplans
                    ?.filter((p) => p?.symbol == c?.symbol)
                    .map((m, i) => (
                      <div
                        key={i}
                        className="flex flex-col gap-1 mx-auto text-center"
                      >
                        <p className="mx-auto text-center">
                          Back In Stock (Limited Offer)
                        </p>
                        <div className="px-10 w-full flex flex-col bg-slate-800  py-6 border-2 border-slate-400 justify-center">
                          <div className="flex flex-col py-3 text-center">
                            <p
                              className={
                                'text-center mx-auto icon icon-' +
                                c.symbol.toLowerCase()
                              }
                              style={{ fontSize: '30px' }}
                            ></p>
                            <span className="text-2xl font-semibold my-2">
                              {m.plan} Plan
                            </span>
                          </div>
                          <div className="flex justify-center">
                            <img
                              src={`/asset/img/miner/miner_${i + 1}.svg`}
                              className="w-[150px] h-auto invert"
                            />
                          </div>
                          <div className="flex justify-center w-full text-3xl font-semibold  border-slate-300 py-3">
                            <span className="text-center my-auto">
                              {user.cur}
                            </span>
                            <span>
                              {m.plan !== 'Custom' ? (
                                Number(m.amount).toPrecision(5)
                              ) : (
                                <input
                                  defaultValue={cusamt}
                                  onChange={(e) => setCusamt(e.target.value)}
                                  className="rounded-sm p-2 w-[50%] bg-transparent border-1 border-slate-100"
                                />
                              )}
                            </span>
                          </div>
                          <div className="w-full text-2xl font-semibold border-t-2 border-slate-300 py-3">
                            {m.hash} TH/s
                          </div>
                          <div className="w-full text-2xl font-semibold border-t-2 border-slate-300 py-3 text-sm">
                            <p>SHA-256 Mining Algorithm</p>
                            <p>Maintenance Fees Apply</p>
                          </div>
                          <Link
                            to={
                              `/office/mining/deposit/new?amount=${
                                m.plan !== 'Custom' ? m.amount : cusamt
                              }&symbol=${m.symbol}&&title=` + m.plan
                            }
                            className="p-3 bg-blue-400 text-white"
                          >
                            PURCHASE PLAN
                          </Link>
                          <p className="my-1 py-6">
                            {(
                              4 +
                              (50 + i * Math.random() * 15) / 100
                            ).toPrecision(3)}{' '}
                            stars, based on{' '}
                            {(20000 + 10 * i * Math.random() * 200).toPrecision(
                              5,
                            )}
                            + reviews
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </Tabs.Item>
            ))}
          </Tabs>
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
