import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
export default function TAbout() {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 bg-darl-800">
        <div>
          {' '}
          <div className="lg:px-20 p-5 text-center">
            <h2 className="text-2xl">About Copy Trading</h2>
            <p className="font-light p-5">
              {process.env.REACT_APP_NAME} Copy Trading is both a product and a
              service. We cater to both the traders and investors looking to
              capitalize in the cryptocurrency trading.
            </p>
            <p className="font-light p-5">
              Being able to copy trades from several expert traders and also
              allow investors to follow one’s trades is a great service in and
              of itself.
            </p>
            <p className="font-light p-5">
              We have been trading markets for many years and recently started
              trading crypto currencies with much success. We originally wanted
              to put together a portfolio of coins and manage everyone’s funds
              in one account but we soon realized that account segregation and
              being in control of your funds is the only way to go, especially
              if we wanted to bring in outside investors. So, after looking
              around and finding out that there was no way for anyone to copy
              someone else’s trades (unlike any other market), we decided to
              start working on this project.
            </p>
            <p className="font-light p-5">
              {' '}
              There have been many challenges and obstacles to overcome but when
              something is worth pursuing you do it even if it’s hard. While the
              service is still being refined and improved on the daily bases we
              are actively connecting traders and investors for the mutual
              benefit in crypto currency trading/investing
            </p>
          </div>
          <div className="flex justify-around text-center  font-semibold lg:flex-row flex-col text-lg py-50">
            <div className="flex flex-col py-5">
              {' '}
              <p className="my-4">Register and Fund Your Account</p>
              <img
                src="/asset/img/vector1.png"
                alt="register"
                className="w-[60%] mx-auto invert-[0.8]"
              />
            </div>

            <div className="flex flex-col text-xl py-5">
              {' '}
              <p className="my-4">Copy One or More Expert Traders</p>
              <img
                src="/asset/img/vector3.png"
                alt="register"
                className="w-[60%] mx-auto invert-[0.8]"
              />
            </div>

            <div className="flex flex-col py-5">
              {' '}
              <p className="my-4"> Watch Your Profits Grow</p>
              <img
                src="/asset/img/vector2.png"
                alt="register"
                className="w-[60%] mx-auto invert-[0.8]"
              />
            </div>
          </div>
        </div>
        <p className="font-light p-5">
          Here is how the copier works: You, as an investor, simply select an
          expert or experts that you want to copy trades from. Once you are
          signed up, this is the only action needed on your part.
        </p>
        <p className="font-light p-5">
          Once you’ve taken care of the above, you are all set. There are no
          codes that you need to run or signals for you to manually input. Our
          software will handle the trade copying automatically on your behalf.
          We monitor your experts trading activity and as soon as there is a
          trade, we calculate all the necessary parameters and execute the
          trade.
        </p>
        <p className="font-light p-5">
          The only thing you have to make sure of is that you have enough funds
          available in your trading account. When the expert exits a position,
          you too will exit it. Automatically.
        </p>
        <h2 className="text-2xl font-semibold text-center">Experts</h2>
        <p className="font-light  text-center mx-auto py-10">
          Below you will find a list of our expert crypto traders and their
          performance. Please note you can have more than one expert
        </p>
        <div class="grid lg:grid-cols-2 grid-cols-1 gap-3">
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t1.png"
              className="w-[120px]   h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">SatoshiChartsTeam</p>
            <p className="font-semibold font-md">
              We are a group of 5 Professional Traders
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Mixed STYLE: Day, Swing, HODL
            </p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t2.gif"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">PipPhenes</p>
            <p className="font-semibold font-md">
              With decades of trading and market expertise you're in good hands
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Mixed STYLE: Day, Swing, HODL
            </p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t3.jpg"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">Copy Digi Millionaires</p>
            <p className="font-semibold font-md">
              19 years of trading experience as a professional trader
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Manual STYLE: Swing
            </p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t4.png"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">Nikolaos Kost</p>
            <p className="font-semibold font-md">
              Professional trader, founder of ccbeast
            </p>
            <p className="text-sm font-light text-center">METHOD: Manual</p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t5.png"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">CryptoCivil</p>
            <p className="font-semibold font-md">
              Professional Engineer and Crypto Trader
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Manual STYLE: Daytrading
            </p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t6.gif"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">TheCryptics</p>
            <p className="font-semibold font-md">
              Group of traders making decisions which coins to buy.
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Manual STYLE: Swing, DayTrade
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
