import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate, Link } from 'react-router-dom'
import TradingViewWidget from '../components/Symbolview'
import { BiPlusCircle, BiNotification, BiAlarm, BiBell } from 'react-icons/bi'
import { Button, Modal } from 'flowbite-react'
export default function Udashboard({
  user,
  coins,
  curData,
  getUser,
  getRates,
  logout,
}) {
  const [openModal, setOpenModal] = useState(false)
  const [voption, setVoption] = useState('')
  const navigate = useNavigate()
  const [navState, setNavState] = useState('hide')
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [IDV, setIDV] = useState('')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  useEffect(() => {
    getRates()
    getUser()
  }, [])
  const uploadID = (e) => {
    e.preventDefault()
    var formData = new FormData(e.target)
    axios
      .post(`${process.env.REACT_APP_SERVER}/?r=uploadid`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Secrete: localStorage.getItem('secrete'),
        },
      })
      .then((response) => {
        if (response?.data?.msg) {
          Swal.fire(
            'ACCOUNT VERIFICATION',
            response?.data?.msg,
            response?.data?.type,
          )
        }
        if (response?.data?.idv) {
          setIDV(response?.data?.idv)
        }
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    if (voption !== '') {
      document
        .querySelectorAll('.modes')
        .forEach((i) => (i.style.display = 'none'))
      document.querySelector('#' + voption).style.display = 'flex'
    }
  }, [voption])
  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} user={user} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      {user?.is_verified == 0 && (
        <>
          <div className="flex gap-2 bg-gray-600  rounded-lg px-5 py-3 w-full lg:w-fit mx-auto my-5 border-2 border-slate-200">
            <BiBell className="my-auto text-xl" />{' '}
            <span className="text-center my-auto">
              Account is <b className="font-bold">Inactive</b>,Please Verify
              Account
            </span>
            <button
              onClick={() => setOpenModal(true)}
              className="bg-slate-500 p-1 border-slate-300 border-1 rounded-sm"
            >
              {' '}
              Verify now
            </button>
          </div>
          <Modal
            show={openModal}
            className="bg-gray-900/40"
            size="lg"
            popup
            onClose={() => setOpenModal(false)}
          >
            <Modal.Header className="w-full">
              <div className="text-center flex justify-center w-full">
                ID VERIFICATION
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="space-y-6">
                <form
                  method="post"
                  encType="multipart/form-data"
                  className="uploadverification flex flex-col justify-center"
                  onSubmit={(e) => uploadID(e)}
                >
                  <select
                    name="voption"
                    className="w-full px-5 vmode text-black rounded-md"
                    onChange={(e) => setVoption(e.target.value)}
                  >
                    <option>Select Verification Mode</option>
                    <option value="PASSPORT">Passport</option>
                    <option value="IDS">Drivers License</option>
                    <option value="IDS">National ID</option>
                  </select>
                  <div
                    className="modes"
                    id="PASSPORT"
                    style={{ display: 'none' }}
                  >
                    <input
                      type="file"
                      name="ID"
                      className="bg-slate-800 rounded-md  border-2 border-slate-200"
                    />
                  </div>{' '}
                  <div
                    id="IDS"
                    className="modes flex flex-col justify-start"
                    style={{ display: 'none' }}
                  >
                    <label className="bg-slate-400 text-white p-1 w-fit">
                      FONT:
                    </label>
                    <input
                      type="file"
                      name="PID1"
                      id="file"
                      className="bg-slate-800 rounded-md  border-2 border-slate-200"
                    />
                    <label className="bg-slate-400 text-white p-1 w-fit">
                      Back:
                    </label>
                    <input
                      type="file"
                      name="PID2"
                      id="file"
                      className="bg-slate-800 rounded-md  border-2 border-slate-200"
                    />
                  </div>
                  {voption !== '' && (
                    <input
                      type="submit"
                      className="bg-blue-600 rounded-lg p-2 w-full"
                      value="Upload"
                      id="btn_upload"
                    />
                  )}
                </form>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
      <div className="py-1 min-h-[450px] px-5 grid lg:grid-cols-3 grid-cols-1 gap-4">
        {coins.map((c, k) => (
          <div className="grid grid-col gap-4" key={k}>
            <div className="bg-slate-800 flex flex-col px-2 w-full py-1 text-center  px-5 py-3 border-2 border-slate-200 rounded-sm">
              <p
                className={'icon icon-' + c.symbol.toLowerCase()}
                style={{ fontSize: '24px' }}
              ></p>
              <div
                className="flex flex-row gap-2 justify-center pt-4 pb-2"
                style={{ fontSize: '24px' }}
              >
                <span>
                  {parseFloat(
                    user?.mbalance ? user.mbalance[c.symbol] : 0,
                  ).toPrecision(9)}
                </span>
                <span>{c.symbol}</span>
              </div>
              <div
                className="flex flex-row gap-1 justify-center pt-1 text-sm text-center pb-3"
                style={{ fontSize: '24px' }}
              >
                <span>{user.cur}</span>
                <span>
                  {parseFloat(
                    parseFloat(user?.mbalance ? user.mbalance[c.symbol] : 0) /
                      c.rate,
                  ).toPrecision(5)}
                </span>
              </div>
              <hr />
              <Link
                to="/office/mining/deposit"
                className="mx-auto py-3 flex justify-center gap-1 font-semibold"
              >
                <BiPlusCircle className="text-xl text-blue-400 my-auto" />
                <span className="text-xl text-blue-400 uppercase">
                  Add Plan
                </span>
              </Link>
            </div>
            <div className="bg-slate-800 px-0 border-2 border-slate-200 rounded-sm relative">
              <TradingViewWidget pair={c.symbol + curData[user.cur]} />
            </div>
          </div>
        ))}
      </div>
      <Ufooter />
    </div>
  )
}
