import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
export default function Contactus() {
  const submitForm = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=contactus'}`,
      data: new FormData(e.target),
      withCredentials: false,
    })
      .then((response) => Swal.fire(response.data))
      .catch((error) => console.error(error))
  }
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }

  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1">
        <form
          method="post"
          className="mt-5 lg:w-[50%] bg-slate-800 w-full px-5 py-10 flex flex-col gap-3 mx-auto"
          action="/contactus"
          onSubmit={submitForm}
        >
          <h2 className="text-2xl mx-auto text-center font-bold">Contact Us</h2>
          <input
            key="name"
            name="name"
            placeholder="Name"
            className="bg-slate-800 border-white text-white  w-full border-2 rounded-lg py-2 px-1"
          />
          <input
            key="email"
            name="email"
            placeholder="Email"
            type="email"
            className="bg-slate-800 border-white text-white w-full border-2 rounded-lg py-2 px-1"
          />
          <input
            key="subject"
            name="subject"
            placeholder="Subject"
            className="bg-slate-800 border-white text-white  w-full border-2 rounded-lg py-2 px-1"
          />
          <textarea
            key="content"
            rows="15"
            name="content"
            placeholder="Message..."
            className="border-white  text-white bg-slate-800 w-full border-2 rounded-lg py-2 px-1"
          ></textarea>
          <button
            key="submit"
            type="submit"
            className="rounded-lg px-2 py-3 bg-slate-500 text-white font-semibold text-lg text-center mx-auto w-full"
          >
            SEND MESSAGE
          </button>
        </form>
      </div>
      <Footer />
    </div>
  )
}
