import { FaBitcoin, FaEthereum, FaCreditCard } from 'react-icons/fa'
import { Link } from 'react-router-dom'
export default function Ufooter() {
  return (
    <footer className="fixed lg:w-[60%] mx-auto w-full left-0 right-0 bottom-0 mb-0  pb-8 h-[13px] pt-5 px-5   bg-slate-800 border-b-2 border-slate-100 flex flex-row  lg:justify-between justify-center gap-4  border-slate-100">
      <Link to="/office/dashboard" className="flex flex-between">
        <span className="material-icons notranslate">copyright</span>
        <span className="lg:visible invisible">Mining</span>
      </Link>
      <Link to="/office/trading" className="flex flex-between">
        <span className="material-icons notranslate">assessment</span>
        <span className="lg:visible invisible">Trading</span>
      </Link>
      <Link to="/office/deposit" className="flex flex-between">
        <span className="material-icons notranslate">input</span>
        <span className="lg:visible invisible">Deposit</span>
      </Link>
      <Link to="/office/profile" className="flex flex-between">
        <span className="material-icons notranslate">account_circle</span>
        <span className="lg:visible invisible">Account</span>
      </Link>
    </footer>
  )
}
