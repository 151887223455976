import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
import { MdEmail } from 'react-icons/md'
import { Accordion } from 'flowbite-react'
export default function TFaq() {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 bg-darl-800">
        <div className="lg:px-20 p-5 text-center">
          <h2 className="text-2xl">Frequently Asked Questions</h2>
          <p>Copy Trading</p>
          <div className="grid  grid-cols-1 lg:px-20 px-5 border-2 border-slate-400 rounded-lg mx-5">
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>Who are the experts?</Accordion.Title>
                <Accordion.Content>
                  We carefully select expert applicants. We get to know them as
                  a trader and examine their trading performance over a period
                  of time. We also tend to look for expert who already have a
                  following to further confirm their competence (social proof).
                  You can also read about every expert on their individual
                  performance pages. For example, you might want to have a look
                  at coinwarz.com. Remember that some of our products have a
                  daily maintenance fee which must be deducted from the daily
                  Trading rewards. Please note that the website is an
                  independent source of reference and not related to
                  {process.env.REACT_APP_NAME} in any manner.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>
                  What is the recommended amount to start with?
                </Accordion.Title>
                <Accordion.Content>
                  The amount you start up with determines your profit, so
                  basically the higher the amount you start with, the higher
                  your profits.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>How does this work?</Accordion.Title>
                <Accordion.Content>
                  Here is how the copier works: You, as an investor, simply
                  select an expert or experts that you want to copy trades from.
                  Once you are signed up, this is the only action needed on your
                  part. Once you’ve taken care of the above, you are all set.
                  There are no codes that you need to run or signals for you to
                  manually input. Our software will handle the trade copying
                  automatically on your behalf. We monitor your experts trading
                  activity and as soon as there is a trade, we calculate all the
                  necessary parameters and execute the trade. The copier works
                  based on trade percent amount. So, for example, if your expert
                  takes a position in XYZ coin for a total of 10% of his account
                  value and you are 100% allocated to that expert, then the
                  copier will also execute a trade in your account in the amount
                  of 10% of your account value. The only thing you have to make
                  sure of is that you have enough available base currency that
                  your expert trades with, in your trading account. How much is
                  enough? First, you must meet the exchanges minimum order
                  amount (let’s say about $10 per trade to be safe). That means
                  that if your expert executes a 5% order, you must have at
                  least $300 in your account total value (at 100% expert
                  allocation as an example). This also means that you need to
                  have at least 10% or higher in available base currency to
                  avoid missed trades. When the expert exits a position, you too
                  will exit it. Automatically. You can also change allocation at
                  any time.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>What are the fees?</Accordion.Title>
                <Accordion.Content>
                  We do not charge you any setup or maintenance fees, each
                  trader gets a set precentage of the profit they make, make
                  sure you check this percentage before copying any trader.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
          <p className="mx-auto text-center mt-10">Other Enquiries?</p>
          <div className="flex gap-2 text-center justify-center">
            {' '}
            <MdEmail className="text-lg my-auto" />
            <span>
              support@
              {process.env.REACT_APP_URL}
            </span>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
