import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Link, useSearchParams } from 'react-router-dom'

export default function Newdeposit({
  coins,
  user,
  curData,
  mplans,
  getRates,
  logout,
}) {
  let [searchParams, setSearchParams] = useSearchParams()
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  useEffect(() => {
    getRates()
  }, [])
  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 w-full">
        <div className="flex flex-2 gap-1 px-3 mt-5">
          <Link to="/office/dashboard">Dashboard</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/deposit">Deposit</Link>
          <FaAngleRight className="my-auto text-lg" />
          {searchParams?.get('symbol') ? (
            <>
              <Link to="/office/mining/deposit">Mining</Link>{' '}
              <FaAngleRight className="my-auto text-lg" />
              <span>New</span>
            </>
          ) : (
            <>
              <Link to="/office/trading/deposit">Trading</Link>{' '}
              <FaAngleRight className="my-auto text-lg" />
              <span>New</span>
            </>
          )}
        </div>
        <h2 className="text-2xl mx-auto text-center">NEW DEPOSIT</h2>
        <div className="flex text-slate-100 mx-auto lg:w-[60%] w-full mx-auto px-3">
          <div className="px-10 w-full flex flex-col bg-slate-800  py-6 border-2 border-slate-400 justify-center">
            <div className="flex flex-col py-3 text-center border-2 border-slate-400">
              <span className="text-2xl font-semibold my-2">
                {' '}
                {searchParams?.get('title')} Plan{' '}
                {searchParams?.get('symbol') ? (
                  <>({searchParams?.get('symbol')})</>
                ) : (
                  ''
                )}
              </span>
              <span className="text-2xl font-semibold my-2">
                {' '}
                {user.cur}
                {searchParams?.get('amount')}
              </span>
            </div>
            <p className="mx-auto">Pay With</p>
            <div className="flex flex-col py-3 text-center gap-2">
              <Link
                to={`/office/deposit/pay?title=${searchParams?.get(
                  'title',
                )}&cur=BTC&amount=${searchParams?.get(
                  'amount',
                )}&coin=${searchParams?.get('symbol')}`}
                className="py-5 px-2 w-full border-2 border-slate-100 bg-slate-600 text-center "
              >
                Bitcoin
              </Link>
              <Link
                to={`/office/deposit/pay?title=${searchParams?.get(
                  'title',
                )}&cur=ETH&amount=${searchParams?.get(
                  'amount',
                )}&coin=${searchParams?.get('symbol')}`}
                className="py-5 px-2 w-full border-2 border-slate-100 bg-slate-600 text-center "
              >
                {' '}
                Ethereum
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
