import { Link } from 'react-router-dom'
import { Accordion } from 'flowbite-react'
export default function Navbar({ toggleNav, navState }) {
  return (
    <div
      className={`navbar px-3 py-5 max-h-100vh overflow-auto max-w-[300px] sidebar ${navState}`}
    >
      <div className="flex justify-end mr-[-5px] text-red-500 hover:text-red-300">
        {' '}
        <span
          onClick={toggleNav}
          className="material-icons notranslate justify-self-end cursor-pointer"
        >
          close
        </span>
      </div>
      <div className="logo col-span-1">
        <img
          src="/logo.png"
          alt="logo"
          className="rounded-full w-[50px] mx-auto"
        />
        <ul className="">
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/account/signin">
              <span className="material-icons notranslate">person_outline</span>
              Sign In
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/account/signup">
              <span className="material-icons notranslate">person_add</span>
              Sign Up
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/payouts">
              <span className="material-icons notranslate">attach_money</span>
              Payouts
            </Link>
          </li>

          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/contactus">
              <span className="material-icons notranslate">email</span>
              Contact Us
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/privacy">
              <span className="material-icons notranslate">
                insert_drive_file
              </span>
              Privacy Policy
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/terms">
              <span className="material-icons notranslate">file_copy</span>
              Terms Of Service
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title className="sidenav-close flex gap-1 p-2">
                  Trading
                </Accordion.Title>
                <Accordion.Content>
                  <ul className="indent-2">
                    <li className="odd:bg-slate-800 even:bg-dark-800">
                      <Link
                        className="sidenav-close flex gap-1 p-2"
                        to="/trading/faqs"
                      >
                        <span className="material-icons notranslate">
                          bar_chart
                        </span>
                        Copy Trading Faqs
                      </Link>
                    </li>
                    <li className="odd:bg-slate-800 even:bg-dark-800">
                      <Link
                        className="sidenav-close flex gap-1 p-2"
                        to="/trading/about"
                      >
                        <span className="material-icons notranslate">
                          bar_chart
                        </span>
                        About Copy Trading
                      </Link>
                    </li>
                    <li className="odd:bg-slate-800 even:bg-dark-800">
                      <Link
                        className="sidenav-close flex gap-1 p-2"
                        to="/trading/experts"
                      >
                        <span className="material-icons notranslate">
                          bar_chart
                        </span>
                        Trading Experts
                      </Link>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title className="sidenav-close flex gap-1 p-2">
                  Mining
                </Accordion.Title>
                <Accordion.Content>
                  <ul className="indent-2">
                    {' '}
                    <li className="odd:bg-slate-800 even:bg-dark-800">
                      <Link
                        className="sidenav-close flex gap-1 p-2"
                        to="/mining/faqs"
                      >
                        <span className="material-icons notranslate">
                          copyright
                        </span>
                        Mining FAQs
                      </Link>
                    </li>
                    <li className="odd:bg-slate-800 even:bg-dark-800">
                      <Link
                        className="sidenav-close flex gap-1 p-2"
                        to="/mining/about"
                      >
                        <span className="material-icons notranslate">
                          copyright
                        </span>
                        About Mining
                      </Link>
                    </li>
                    <li className="odd:bg-slate-800 even:bg-dark-800">
                      <Link
                        className="sidenav-close flex gap-1 p-2"
                        to="/mining/datacenters"
                      >
                        <span className="material-icons notranslate">
                          copyright
                        </span>
                        Datacenters
                      </Link>
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </li>
        </ul>
      </div>
    </div>
  )
}
