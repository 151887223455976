import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate, Link } from 'react-router-dom'
export default function Reset() {
  const navigate = useNavigate()
  const [navState, setNavState] = useState('hide')
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [cpwd, setCpwd] = useState('')
  const [vcode, setVcode] = useState('')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  function sendcode() {
    if (email !== '') {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER + '?r=resetcode'}`,
        withCredentials: false,
        data: { email: email },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((response) => {
          if (response?.data?.includes('success')) {
            Swal.fire('Code sent to Mail')
            if (response.data?.includes('-')) {
              localStorage.setItem('resetcode', response.data.split('-')[1])
            }
          } else {
            Swal.fire(response?.data)
          }
        })
        .catch((error) => console.error(error))
    } else {
      Swal.fire('PASSWORD RESET', 'Password Cannot be empty', 'warning')
    }
  }
  const reset = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=reset'}`,
      withCredentials: false,
      data: {
        email: email,
        pwd: pwd,
        cpwd: cpwd,
        vcode: vcode,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Resetcode: localStorage.getItem('resetcode'),
      },
    })
      .then((response) => {
        Swal.fire(
          'RESET',
          response?.data?.msg,
          response?.data?.type === 'success' ? 'success' : 'warning',
        )
        if (response?.data?.type === 'success') {
          localStorage.setItem('secrete', response?.data?.secrete)
          navigate('/office/dashboard')
        }
      })
      .catch((error) => console.error(error))
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 min-h-[450px] px-5">
        <form
          onSubmit={(e) => reset(e)}
          method="post"
          className="my-10 rounded-lg shadow-lg mt-[100px] mt-5 lg:w-[50%] bg-slate-800 w-full px-5 py-10 flex flex-col gap-3 mx-auto"
          action="/reset"
        >
          <h2 className="text-2xl mx-auto text-center font-bold">
            RESET PASSWORD
          </h2>
          <input
            onChange={(e) => setEmail(e.target.value)}
            key="email"
            name="email"
            placeholder="Email"
            type="email"
            className="bg-slate-800 border-white text-white  w-full border-2 rounded-lg py-2 px-1"
          />
          <div className="flex">
            {' '}
            <input
              onChange={(e) => setVcode(e.target.value)}
              name="code"
              key="code"
              className="w-[30%] bg-slate-800 border-white text-white w-full border-2 rounded-l-lg py-2 px-1"
            />
            <button
              onClick={(e) => sendcode()}
              key="sendcode"
              type="button"
              className="w-[70%] bg-slate-800 border-white text-white  w-full border-2 rounded-r-lg py-2 px-1"
            >
              SendCode
            </button>
          </div>
          <input
            onChange={(e) => setPwd(e.target.value)}
            key="password"
            name="pwd"
            type="password"
            placeholder="Password"
            className="bg-slate-800 border-white text-white w-full border-2 rounded-lg py-2 px-1"
          />
          <input
            onChange={(e) => setCpwd(e.target.value)}
            key="cpassword"
            name="cpwd"
            type="password"
            placeholder="Confirm Password"
            className="bg-slate-800 border-white text-white w-full border-2 rounded-lg py-2 px-1"
          />
          <button
            key="submit"
            type="submit"
            className="rounded-lg px-2 py-3 bg-slate-500 text-white font-semibold text-lg text-center mx-auto w-full"
          >
            RESET
          </button>
          <div className="text-center">
            Don't have an account?{' '}
            <Link to="/account/signup" className="text-slate-200">
              Signup
            </Link>
          </div>
          <div className="text-center">
            Already Have an account?
            <Link to="/account/signin" className="text-slate-200">
              Login
            </Link>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  )
}
