import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import React, { useState, useEffect, useRef, memo } from 'react'
import { BiPlusCircle, BiCopyAlt } from 'react-icons/bi'
import TradingViewWidget from '../components/Symbolview'
import axios from 'axios'

import Swal from 'sweetalert2'
import { useNavigate, Link } from 'react-router-dom'

export default function Withdraw({
  user,
  getUser,
  getTraders,
  traders,
  logout,
  coins,
}) {
  const [navState, setNavState] = useState('hide')
  const [withdrawals, setWithdrawals] = useState([])
  const [wMethod, setwMethod] = useState('BTC')
  function getWithdrawals() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=withdrawals'}`,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setWithdrawals(response.data)
      })
      .catch((error) => console.error(error))
  }
  const Withdraw = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/?r=withdraw`,
      data: new FormData(e.target),
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        Swal.fire('WITHDRAWAL', response?.data?.msg, response?.data?.type)
        getWithdrawals()
      })
      .catch((error) => console.error(error))
  }
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }

  useEffect(() => {
    getUser()
    getWithdrawals()
  }, [])
  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 min-h-[450px] px-5">
        <div className="lg:w-[60%] w-full mx-auto flex flex-col gap-3 lg:px-[50px] px-2">
          <div className="gap-3 bg-slate-800">
            <div className="bg-slate-800 flex flex-col px-1 w-full py-5 text-center py-1 border-2 border-slate-400 rounded-sm justify-center">
              <h2 className="text-2xl text-semibold"> WITHDRAWALS</h2>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="py-1 border-2 border-slate-400 rounded-sm justify-center">
              <form
                method="post"
                className="w-full flex flex-col py-10 px-3 "
                onSubmit={(e) => Withdraw(e)}
              >
                <label>Withdrawal Method</label>
                <select
                  name="method"
                  required
                  onChange={(e) => setwMethod(e.target.value)}
                  className="w-full px-5 vmode text-black rounded-md"
                >
                  <option value="">Select Method</option>
                  <option value="BTC">Bitcoin</option>
                  <option value="ETH">Ethereum</option>
                  <option value="PayPal">PayPal</option>
                  <option value="Bank">Bank</option>
                </select>
                <label>Withdrawal Account</label>
                <select
                  name="account"
                  className="w-full px-5 vmode text-black rounded-md"
                >
                  <option value="Trading" selected="">
                    Trading ({user.cur + user.balance})
                  </option>
                  <option value="Bonus" selected="">
                    Bonus ({user.cur + user.bonus})
                  </option>
                  {coins?.map((c, k) => (
                    <option value={c.symbol} key={k + 2}>
                      {c.symbol} Mining ({' '}
                      {parseFloat(
                        user?.mbalance ? user.mbalance[c.symbol] : 0,
                      ).toPrecision(9) + c.symbol}
                      )
                    </option>
                  ))}
                </select>
                <div>
                  <label>Withdrawal Amount({user.cur})</label>
                  <input
                    name="amount"
                    type="number"
                    step="0.00001"
                    className="w-full p-2 rounded-md my-2 text-black"
                    placeholder={user.cur + 'Amount '}
                    required
                  />
                </div>

                {(wMethod === 'BTC' || wMethod === 'ETH') && (
                  <div>
                    <label>Wallet Address({wMethod})</label>
                    <input
                      type="text"
                      className="w-full p-2 rounded-md my-2 text-black"
                      placeholder="Wallet Address"
                      name="address"
                    />
                  </div>
                )}
                {wMethod === 'PayPal' && (
                  <div>
                    <label>{wMethod} Email</label>
                    <input
                      type="text"
                      className="w-full p-2 rounded-md my-2 text-black"
                      placeholder="Paypal Email"
                      name="address"
                    />
                  </div>
                )}
                {wMethod === 'Bank' && (
                  <div>
                    <label>Bank Name</label>
                    <input
                      type="text"
                      className="w-full p-2 rounded-md my-2 text-black"
                      placeholder="Bank Name"
                      name="bname"
                    />
                    <label>Account Name</label>
                    <input
                      type="text"
                      className="w-full p-2 rounded-md my-2 text-black"
                      placeholder="Account Name"
                      name="aname"
                    />
                    <label>Account Number</label>
                    <input
                      type="text"
                      className="w-full p-2 rounded-md my-2 text-black"
                      placeholder="Account Number"
                      name="anumber"
                    />
                    <label>Routing Number/IBAN</label>
                    <input
                      type="text"
                      className="w-full p-2 rounded-md my-2 text-black"
                      placeholder="Routing Number/IBAN"
                      name="rnumber"
                    />
                  </div>
                )}
                <input
                  type="submit"
                  className="bg-slate-600 rounded-lg p-2 w-full"
                  value="WITHDRAW"
                  id="btn_upload"
                />
              </form>
            </div>
          </div>
        </div>
        <div className="bg-slate-700 py-10 px-2">
          <div className="relative overflow-x-auto" id="tradehistory">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Method
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {withdrawals?.length > 0 &&
                  withdrawals?.map((t, k) => (
                    <tr className="bg-white dark:bg-gray-800 " key={k}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {t.acct == 'Trading' || t.acct == 'Bonus'
                          ? user.cur + Number(t.amount).toLocaleString()
                          : Number(t.amount).toLocaleString() + t.acct}
                      </th>
                      <td className="px-6 py-4">{t.date}</td>
                      <td className="px-6 py-4">{t.method}</td>
                      <td
                        className={
                          'px-6 py-4 ' + t.status === 'COMPLETED'
                            ? 'text-green-400'
                            : 'text-red-400'
                        }
                      >
                        {t.status}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
