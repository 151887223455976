import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Tabs } from 'flowbite-react'
export default function Deposit({
  coins,
  user,
  curData,
  mplans,
  deposits,
  getDeposits,
  logout,
}) {
  const [navState, setNavState] = useState('hide')

  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }

  useEffect(() => {
    getDeposits()
  }, [])
  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 w-full">
        <div className="flex flex-2 gap-1 px-3 mt-5">
          <Link to="/office/dashboard">Dashboard</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/deposit">Deposit</Link>
        </div>
        <h2 className="text-2xl mx-auto text-center">DEPOSIT</h2>
        <div className="flex text-slate-100 mx-auto w-full">
          <div className="bg-slate-800 flex flex-col py-10 px-4 lg:w-[60%] w-full px-3 gap-3 mx-auto mt-10">
            <Link
              to="/office/trading/deposit"
              className="py-5 px-2 w-full border-2 border-slate-100 bg-slate-600 text-center"
            >
              <span className="material-icons notranslate my-auto ">
                assessment
              </span>{' '}
              Trading
            </Link>
            <Link
              to="/office/mining/deposit"
              className="py-5 px-2 w-full border-2 border-slate-100 bg-slate-600 text-center"
            >
              <span className="material-icons notranslate my-auto">
                how_to_vote
              </span>{' '}
              Mining
            </Link>
          </div>
        </div>
        <hr />
        <div className="bg-slate-700 py-10 px-2">
          <div className="relative overflow-auto">
            <table
              id="history"
              className="mx-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
            >
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Plan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Asset
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {deposits?.length < 1 && (
                  <tr className="dark:bg-gray-800 even:bg-gray-100 odd:bg-white">
                    <th
                      colSpan={5}
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      No Deposits Yet
                    </th>
                  </tr>
                )}
                {deposits?.length > 0 &&
                  deposits?.map((d, k) => (
                    <tr
                      className="dark:bg-gray-800 even:bg-gray-100 odd:bg-white"
                      key={k}
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {d.plan}
                      </th>
                      <td className="px-6 py-4">{d.amount}</td>
                      <td className="px-6 py-4">
                        {d.coin === null || d.coin === 'null'
                          ? 'Trading'
                          : `Mining(${d.coin})`}
                      </td>
                      <td className="px-6 py-4">{d.dt}</td>
                      <td
                        className={
                          d.status === 'CONFIRMED'
                            ? 'text-green-400 px-3 py-2  w-[120px] mx-auto my-1'
                            : 'text-red-400 px-3 py-2 w-[120px] mx-auto my-1'
                        }
                      >
                        {d.status}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
