import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
import { Accordion } from 'flowbite-react'

export default function Privacy() {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1">
        <h2 className="text-2xl">Privacy Policy</h2>
        <div className="font-light p-5">
          <p>Last Revised: April 30th, 2020</p>
          <h2 className="text-2xl font-semibold">Introduction</h2>
          <p>
            {process.env.REACT_APP_NAME} Limited (in incorporation) (“us“, “we”
            or “Company“) respect the privacy of our users (each, “you” or
            “User“) and are committed to protect the privacy of Users who
            access, download, install or register to our mobile application (the
            “Application“), our website or any other online services we provide
            (collectively: the “Services“). The Company has papered this Privacy
            Policy to outline our practices with respect to collecting, using
            and disclosing your information when you use the Services. We
            encourage you to read the Privacy Policy carefully and use it to
            make informed decisions. By using the Services, you agree to the
            terms of this Privacy Policy and your continued use of the Services
            constitutes your ongoing agreement to the Privacy Policy. The
            Privacy Policy is a part of the Terms of Service and is incorporated
            there in by reference. In this Privacy Policy you will read about,
            among other things:
          </p>
        </div>
        <div className="font-light p-5">
          {process.env.REACT_APP_NAME} is the next generation of Digital
          Assets-related services, serving as a value-driven platform for all
          members of the
          {process.env.REACT_APP_NAME} community. {process.env.REACT_APP_NAME}{' '}
          allows Users to take advantage of a variety services, all in
          accordance with applicable law and regulation, including:
          <ul>
            <li>What type of information we collect</li>
            <li>Cookies and Google Analytics</li>
            <li>How we use the information</li>
            <li> With whom we share the information and for what purpose</li>
            <li>For how long we retain the information</li>
            <li>How we protect your information</li>
            <li>Advertisements</li>
            <li>Advertising ID and Advertising Identifier</li>
            <li>How to contact us</li>
          </ul>
          <p>
            {process.env.REACT_APP_NAME} is built around cutting edge technology
            that allows Users to take advantage of different utilities,
            primarily to gain better terms and opportunities when using{' '}
            {process.env.REACT_APP_NAME} Services.
          </p>
        </div>
        <p className="font-light p-5"></p>
      </div>
      <div className="flex flex-col py-10">
        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>
              What type of information we collect
            </Accordion.Title>
            <Accordion.Content>
              <p>
                {' '}
                We may collect two types of data and information from our Users.
                The first type of information is un-identified and
                non-identifiable information pertaining to you, which may be
                made available or gathered via your use of the Services
                (“Non-personal Information“). We are not aware of the identity
                from which the Non-personal Information is collected.
                Non-personal Information which is collected may include your
                aggregated usage information and technical information
                transmitted by your device, including certain software and
                hardware information about your device (e.g., the device you
                use, the type of browser and operating system your device uses,
                language preference, access time and the website’s domain name
                from which you linked to the Services, etc.), in order to
                enhance the functionality of the Services. We may also collect
                information about your activity on the Services (e.g., pages
                viewed, online browsing, clicks, actions, timestamps, etc.). The
                second type of information is individually identifiable
                information, namely information that identifies an individual or
                may with reasonable effort identify an individual (“Personal
                Information“). Such information may include: Account
                Information: When you sign-up and register to the Services, you
                will be asked to provide us certain details about yourself. You
                may register to the Services through our website.
              </p>
              <p></p>
              <p>
                Registering through the our website: When you register to the
                Services through the Application or our website, we may collect
                from you the following information: full name, email address,
                home address, hometown, nationality, birthdate, phone number, as
                well as information required for our compliance with regulatory
                Know Your Customer requirements, such as passport or other
                identification number, and any other information you agreed to
                share with us.
              </p>
              <p>
                Voluntarily Information: We may collect information which you
                provide us voluntarily. For instance, when you respond to
                communications from us, communicate with us via email or share
                additional information about yourself through your use of the
                Services. We may also collect the feedback, suggestions,
                complaints and reports which you send to us. Please note that we
                may also collect complaints about you from other Users, which
                may include your Personal Information.
              </p>
              <p>
                For the avoidance of doubt, if we combine Personal Information
                with Non-personal Information, the combined information will be
                treated as Personal Information as long as it remains combined.
              </p>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>Cookies and Google Analytics</Accordion.Title>
            <Accordion.Content>
              <p>
                {' '}
                We may use cookies and other technologies or methods of web and
                mobile analysis (e.g., pixels, FullStory, Google Analytics,
                etc.) to gather, store, and track certain information related
                with your access to and activity through the Services, including
                when you visit our website. A “cookie” is a small piece of
                information that a website assign to your device while you are
                viewing a website. Cookies are very helpful and may be used for
                various different purposes. These purposes include, among other
                things, allowing you to navigate between pages efficiently,
                enabling automatic activation of certain features, remembering
                your preferences and making the interaction between you and the
                Services quicker, easier and smoother. Our website may use the
                following types of cookies:
              </p>
              <p>
                Session Cookies which are stored only temporarily during a
                browsing session in order to allow normal use of the system and
                are deleted from your device when the browser is closed.
              </p>
              <p>
                Persistent Cookies which are saved on your device for a fixed
                period and are not deleted when the browser is closed. Such
                cookies are used where we need to identify who you are for
                repeated visits (for instance, to allow us to store your
                preferences for the next sign-in).
              </p>
              <p>
                Third-Party Cookies which are set by other online services that
                run content on the page you view on your browser (for example,
                by third-party advertising companies who monitor and analyze our
                website access).
              </p>
              <p>
                You may remove the cookies by following the instructions of your
                device preferences. However, if you choose to disable cookies,
                some features of the Services may not operate properly and your
                online experience may be limited. We also use a tool called
                Google Analytics to collect information about your use of the
                Services. Google Analytics collects information such as how
                often users access the Services, what pages they visit when they
                do so, etc. We use the information we get from Google Analytics
                only to improve our Services. Google Analytics collects the IP
                address assigned to you on the date you visit sites, rather than
                your name or other identifying information. We do not combine
                the information collected through the use of Google Analytics
                with personally identifiable information. Google’s ability to
                use and share information collected by Google Analytics about
                your visits to this site is restricted by the Google Analytics
                Terms of Service and the Google Privacy Policy.
              </p>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>How we use the information</Accordion.Title>
            <Accordion.Content>
              <p>
                We use and share Personal Information in the manners described
                in this Privacy Policy. In addition to the purposes listed
                above, the information we collect, which may include your
                Personal Information, is used for the following purposes:
              </p>
              <p>To set up your account and to provide the Services.</p>
              <p>To identify and authenticate your access to the Services.</p>
              <p>
                To authenticate your identity for the purpose of compliance with
                regulatory Know Your Customer requirements.
              </p>
              <p>
                To send you relevant push notifications, which are based on
                different activities offered by the Services.
              </p>
              <p>
                To communicate with you and to keep you informed of our latest
                updates and newsletters.
              </p>
              <p>To market our website, products, and the Services.</p>
              <p>
                To serve you personalized advertisements when you use the
                Services.
              </p>
              <p>
                To perform a research or to conduct analytics in order to
                improve and customize the Services to your needs and interests.
              </p>
              <p>
                To support and troubleshoot the Services and to respond to your
                queries.
              </p>
              <p>
                To investigate and resolve disputes in connection with your use
                of the Services.
              </p>
              <p>
                To detect and prevent fraudulent and illegal activity or any
                other type of activity that may jeopardize or negatively affect
                the integrity of the Services; and
              </p>
              <p>
                To investigate violations and enforce our policies, and as
                required by law, regulation or other governmental authority, or
                to comply with a subpoena or similar legal process or respond to
                a government request.
              </p>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>
              With whom we share the information and for what purpose
            </Accordion.Title>
            <Accordion.Content>
              <p>
                {' '}
                We do not rent, sell, or share your Personal Information with
                third-parties except as described in this Privacy Policy. We may
                share Personal Information with the following recipients: (i)
                our subsidiaries; (ii) affiliated companies; (iii)
                subcontractors and other third-party service providers; (iv)
                auditors or advisers of our business processes; and (v) any
                potential purchasers or investors in the company. In addition to
                the purposes listed in this Privacy Policy, we may share
                Personal Information with our recipients for any of the
                following purposes: (i) storing or processing Personal
                Information on our behalf (e.g., cloud computing service
                providers); (ii) processing such information to assist us with
                our business operations; (iii) performing research, technical
                diagnostics, personalization and analytics. We may also disclose
                Personal Information or any information you submitted via the
                Services if we have a good faith belief that disclosure of such
                information is helpful or reasonably necessary to: (i) comply
                with any applicable law, regulation, legal process or
                governmental request; (ii) enforce our policies, including
                investigations of potential violations thereof; (iii)
                investigate, detect, prevent, or take action regarding illegal
                activities or other wrongdoing, suspected fraud or security
                issues; (iv) to establish or exercise our rights to defend
                against legal claims; (v) prevent harm to the rights, property
                or safety of us, our affiliates, our Users, yourself or any
                third-party; (vi) for the purpose of collaborating with law
                enforcement agencies; and (vii) in case we find it necessary in
                order to enforce intellectual property or other legal rights.
              </p>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>
              Third-party collection of information
            </Accordion.Title>
            <Accordion.Content>
              Our policy only addresses the use and disclosure of information we
              collect from you. To the extent that you disclose your information
              to other parties via the Services (e.g., by clicking on a link to
              any other website or location) or via other sites throughout the
              Internet, different rules may apply to their use or disclosure of
              the information you disclose to them. You acknowledge that we are
              not responsible for the products, services, or descriptions of
              products or services that you receive from third-party sites or to
              the content or privacy practices of those sites, and that this
              Privacy Policy does not apply to any such third-party products and
              services. You are knowingly and voluntarily assuming all risks of
              using third-party sites to purchase products and services. You
              agree that we shall have no liability whatsoever with respect to
              such third-party sites and your usage of them.
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>
              For how long we retain the information
            </Accordion.Title>
            <Accordion.Content>
              We respect your privacy rights and therefore you may contact us at
              any time and request: (i) to access, delete, change or update any
              Personal Information relating to you (for example, if you believe
              that your Personal Information is incorrect, you may ask to have
              it corrected or deleted); or (ii) that we will cease any further
              use of your Personal Information (for example, you may ask that we
              will stop using or sharing your Personal Information with
              third-parties) or that we shall remove your Personal Information
              (subject to any other legal obligation that may require us to keep
              the information). Please note that unless you instruct us
              otherwise we retain the information we collect for as long as
              needed to provide the Services and to comply with our legal
              obligations, resolve disputes and enforce our agreements. We may
              rectify, replenish or remove incomplete or inaccurate information,
              at any time and at our own discretion. If you wish to raise a
              complaint on how we have handled your Personal Information, please
              contact us directly at info@{process.env.REACT_APP_URL}. If you
              are not satisfied with our response or believe we are collecting
              or processing your Personal Information not in accordance with the
              laws, you can complain to the applicable data protection
              authority.
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>How we protect your information</Accordion.Title>
            <Accordion.Content>
              <p>
                {' '}
                We take great care in implementing and maintaining the security
                of the Services and your information. We employ industry
                standard procedures and policies to ensure the safety of your
                information and prevent unauthorized use of any such
                information. Although we take reasonable steps to safeguard
                information, we cannot be responsible for the acts of those who
                gain unauthorized access or abuse the Services, and we make no
                warranty, express, implied or otherwise, that we will prevent
                such access. If you feel that your privacy was treated not in
                accordance with our policy, or if any person attempted to abuse
                the Services or acted in an inappropriate manner, please contact
                us directly at support@{process.env.REACT_APP_URL}.
              </p>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>Advertisements</Accordion.Title>
            <Accordion.Content>
              We may use a third-party advertising technology to serve
              advertisements when you use the Services. This technology uses
              your information with regards to your use of the Services to serve
              advertisements to you (e.g., by placing third-party cookies on
              your web browser). We may also use our third-parties and share
              with them Users’ information to assist us in evaluating the
              success of our advertising campaigns and help us retargeting our
              Users. You may opt-out of many third-party ad networks, including
              those operated by members of the Network Advertising Initiative
              (“NAI”) and the Digital Advertising Alliance (“DAA”). For more
              information about this practice by NAI and DAA members, and your
              choices regarding having this information used by these companies,
              including how to opt-out of third-party ad networks operated by
              NAI and DAA members, please visit their respective websites:
              http://optout.networkadvertising.org/#!/ and
              http://optout.aboutads.info/#!/.
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>Corporate transaction</Accordion.Title>
            <Accordion.Content>
              We may share information, including Personal Information, in the
              event of a corporate transaction (e.g., sale of a substantial part
              of our business, merger, consolidation or asset sale of an asset
              or transfer in the operation thereof) of the Company. In the event
              of the above, the acquiring company or transferee will assume the
              rights and obligations as described in this Privacy Policy.
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>
              Advertising ID and Advertising Identifier
            </Accordion.Title>
            <Accordion.Content>
              The Google Advertising ID is an anonymous identifier, provided by
              Google Play services. If your device has an Advertising ID, we may
              collect and use it for advertising and user analytics purposes. We
              may also use Apple’s Advertising Identifier (IDFA), which is a
              non-permanent device identifier provided by Apple, and any
              information obtained through the use of the Advertising
              Identifier, for the purpose of advertising. By downloading the
              Application or using the Services you explicitly agree that we may
              associate your Advertising ID and your Advertising Identifier with
              your applicable persistent device identifier. This will facilitate
              our ability to improve your personalized experience. Further, we
              may use other persistent identifiers for non-advertising purposes.
              If your device does not have Advertising ID or Advertising
              Identifier respectively, we will use other identifiers.
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>
              Updates or amendments to the Privacy Policy
            </Accordion.Title>
            <Accordion.Content></Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>
              Updates or amendments to the Privacy Policy
            </Accordion.Title>
            <Accordion.Content>
              We may revise this Privacy Policy from time to time, in our sole
              discretion, and the most current version will always be posted on
              our website or Application (as reflected in the “Last Revised”
              heading). In the event of a material change to the Privacy Policy,
              we will notify you through the Services or via email, or you may
              be notified within the Application. We encourage you to review
              this Privacy Policy regularly for any changes. Your continued use
              of the Services, following the notification of such amendments,
              constitutes your acknowledgement and consent of such amendments to
              the Privacy Policy and your agreement to be bound by the terms of
              such amendments.
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>

        <Accordion className="border-0 my-2">
          <Accordion.Panel>
            <Accordion.Title>How to contact us</Accordion.Title>
            <Accordion.Content>
              If you have any general questions regarding the Services or the
              information that we collect about you and how we use it, please
              contact us at support@{process.env.REACT_APP_URL}.
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </div>
      <Footer />
    </div>
  )
}
