import { FaBars, FaUserLock, FaUserPlus, FaUserCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import useLocalStorage from 'use-local-storage'
import { BiLogOut, BiToggleLeft, BiToggleRight } from 'react-icons/bi'
export default function Header({ toggleNav }) {
  const [theme, setTheme] = useLocalStorage('dark', null)
  useEffect(() => {
    document.documentElement.setAttribute('theme', theme)
  }, [theme])
  return (
    <header className="border-b-2 border-slate-100 bg-gray-900 flex flex-col justify-between py-5 border-slate-100">
      <div className="lg:grid lg:grid-cols-5 flex-row-reverse flex lg:justify-around justify-between gap-2 flex-row lg:mx-20 mx-5">
        <div className="col-span-1">
          <Link to="/">
            {' '}
            <img src="/logo.png" alt="logo" className="h-[75px]" />
          </Link>
        </div>
        <div className="m-none flex justify-end gap-10 lg:col-span-3 col-span-0 font-semibold uppercase py-2">
          <div className="dropdown">
            Copy Trading
            <div className="content">
              <Link to="/trading/faqs">Copy Trading FAQs</Link>
              <Link to="/trading/about">About Copy Trading</Link>
              <Link to="/trading/experts">Trading Experts</Link>
            </div>
          </div>
          <div className="dropdown">
            Mining
            <div className="content">
              <Link to="/mining/faqs">Mining FAQs</Link>
              <Link to="/mining/about">About Mining</Link>
              <Link to="/mining/datacenters">Mining Datacenters</Link>
            </div>
          </div>

          <Link to="/account/signin" className="flex gap-1">
            <span>signin</span>
            <FaUserLock className="font-bold" />{' '}
          </Link>
          <span>|</span>
          <Link to="/account/signup" className="flex gap-1">
            <span>signup</span>
            <FaUserPlus className="font-bold" />{' '}
          </Link>
        </div>
        <div className="col-span-1 justify-self-end flex gap-5 justify-between">
          <span
            title="Change Theme"
            className="font-bold text-center my-auto uppercase"
          >
            {theme == 'dark' ? (
              <BiToggleLeft
                onClick={() => setTheme('light')}
                className="my-auto text-center text-4xl text-blue-500"
              />
            ) : (
              <BiToggleRight
                onClick={() => setTheme('dark')}
                className="my-auto text-center text-4xl text-gray-00"
              />
            )}
          </span>

          <Link href="#" onClick={toggleNav} className="my-auto ">
            <FaBars className="my-auto text-center text-2xl" />
          </Link>
        </div>
      </div>{' '}
    </header>
  )
}
