import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState, useEffect } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useNavigate, Link } from 'react-router-dom'
export default function Signup() {
  const navigate = useNavigate()
  const [navState, setNavState] = useState('hide')
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  const login = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=login'}`,
      withCredentials: false,
      data: {
        email: email,
        pwd: pwd,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        Swal.fire(
          'LOGIN',
          response?.data?.msg,
          response?.data?.type === 'success' ? 'success' : 'warning',
        )
        if (response?.data?.type === 'success') {
          localStorage.setItem('secrete', response?.data?.secrete)

          return navigate('/office/dashboard')
        }
      })
      .catch((error) => console.error(error))
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 min-h-[450px] px-5">
        <form
          onSubmit={(e) => login(e)}
          method="post"
          className="my-10 rounded-lg shadow-lg mt-[100px] mt-5 lg:w-[50%] bg-slate-800 w-full px-5 py-10 flex flex-col gap-3 mx-auto"
          action="/signin"
        >
          <h2 className="text-2xl mx-auto text-center font-bold">SIGNIN</h2>
          <input
            onChange={(e) => setEmail(e.target.value)}
            key="email"
            name="email"
            placeholder="Email"
            type="email"
            className="bg-slate-800 border-white text-white  w-full border-2 rounded-lg py-2 px-1"
          />
          <input
            onChange={(e) => setPwd(e.target.value)}
            key="password"
            name="pwd"
            type="password"
            placeholder="Password"
            className="bg-slate-800 border-white text-white w-full border-2 rounded-lg py-2 px-1"
          />
          <button
            key="submit"
            type="submit"
            className="rounded-lg px-2 py-3 bg-slate-500 text-white font-semibold text-lg text-center mx-auto w-full"
          >
            SIGNIN
          </button>
          <div className="text-center">
            Don't have an account?{' '}
            <Link to="/account/signup" className="text-slate-200">
              Signup
            </Link>
          </div>
          <div className="text-center">
            Forgot Password?
            <Link to="/account/reset" className="text-slate-200">
              Reset Account
            </Link>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  )
}
