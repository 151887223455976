import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useEffect, useRef, useState } from 'react'
import { FaAngleRight, FaTimesCircle, FaPlusCircle } from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'

export default function Traders({
  user,
  getUser,
  getTraders,
  traders,
  logout,
}) {
  const [navState, setNavState] = useState('hide')
  const [Tid, setTid] = useState(0)
  const [activetrader, setActivetrader] = useState({})
  const params = useParams()
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }

  function Copytrader(id) {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=copytrade'}`,
      withCredentials: false,
      data: { id },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        Swal.fire('COPY TRADER', response.data.msg, 'success')
        getUser()
      })
      .catch((error) => console.error(error))
  }
  useEffect(() => {
    getTraders()
    if (params?.tid) {
      setTid(params?.tid)
    }
  }, [])
  useEffect(() => {
    if (params?.tid) {
      setActivetrader(traders?.filter((t) => t?.id === params?.tid)?.pop())
    } else {
      setActivetrader({})
    }
  }, [Tid, traders, params.tid])

  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 w-full">
        <div className="flex flex-2 gap-1 px-3 mt-5">
          <Link to="/office/trading">Trading</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/traders">Traders</Link>
        </div>
        <h2 className="text-2xl mx-auto text-center">
          {!activetrader?.name ? 'Traders' : activetrader?.name}
        </h2>
        <div className="flex text-slate-100 mx-auto lg:w-[80%] w-full mx-auto px-2 justify-items-stretch">
          {!params?.tid && (
            <div className="px-3 w-full grid lg:grid-cols-4 grid-cols-1 justify-center gap-3">
              <div className="lg:col-span-3 col-span-1 flex flex-col py-3 text-center gap-2 bg-slate-800">
                {traders?.map(
                  (t, k) =>
                    t !== '' && (
                      <div
                        key={k}
                        className="relative flex justify-start bg-slate-800  gap-5 px-2 py-5 border-b-2 border-slate-100"
                      >
                        <div className="absolute right-0 top-0">
                          {' '}
                          {user?.traders &&
                          user?.traders !== '' &&
                          typeof user?.traders == 'string' &&
                          user?.traders?.split(',')?.includes(t.id) ? (
                            <FaTimesCircle
                              className="text-xl text-red-500 hover:text-red-300"
                              title="Disconnect"
                              onClick={() => Copytrader(t.id)}
                            />
                          ) : (
                            <FaPlusCircle
                              onClick={() => Copytrader(t.id)}
                              className="text-xl text-green-500 hover:text-green-300"
                              title="Connect"
                            />
                          )}
                        </div>
                        <div className="p-1">
                          <img
                            src={
                              process.env.REACT_APP_SERVER.replace(
                                'app.php',
                                '',
                              ) + t.pic
                            }
                            className="rounded-full p-2 h-[60px] my-auto"
                            alt="trader"
                          />
                        </div>
                        <div className="flex flex-col gap-1 my-auto justify-center text-left font-semibold">
                          <p>Win Rate: {t?.wr}%</p>
                          <p>Profit Share: {t.ps}%</p>
                          <p>{t.note}</p>
                        </div>
                      </div>
                    ),
                )}
              </div>
              <div className="col-span-1 flex flex-col py-4 text-center gap-2 bg-slate-800 px-4 ">
                <h2
                  className="text-2xl mx-auto text-center text-sm"
                  id="mytraders"
                >
                  My Traders
                </h2>
                {user?.traders &&
                  user?.traders !== '' &&
                  typeof user?.traders == 'string' &&
                  traders
                    .filter((mt) =>
                      user?.traders?.split(',').toString()?.includes(mt.id),
                    )
                    ?.map((u, k) => (
                      <div key={k}>
                        <Link
                          to={'/office/traders/' + u.id}
                          className="bg-slate-300 p-2 w-full text-black flex gap-2 rounded-tr-lg rounded-bl-lg"
                        >
                          <img
                            src={
                              process.env.REACT_APP_SERVER.replace(
                                'app.php',
                                '',
                              ) + u.pic
                            }
                            className="rounded-full p-2 h-[50px] my-auto"
                            alt="trader"
                          />
                          <span className="text-center my-auto">{u?.name}</span>
                        </Link>
                      </div>
                    ))}
                {(!user?.traders || user?.traders === '') && (
                  <div className="px-3 py-2 bg-slate-200 text-black">
                    No Copied Traders
                  </div>
                )}
              </div>
            </div>
          )}
          {params.tid && (
            <div className="px-2 py-3 bg-slate-800 flex flex-col lg:w-[60%] w-full justify-center mx-auto">
              <div className="flex flex-col justify-center">
                <img
                  src={
                    process.env.REACT_APP_SERVER.replace('app.php', '') +
                    activetrader?.pic
                  }
                  className="rounded-full p-2 h-[80px] my-auto mx-auto"
                  alt="trader"
                />
                <p className="text-center font-semibold">
                  {activetrader?.note}
                </p>
              </div>
              <button
                onClick={() => Copytrader(activetrader?.id)}
                className="w-fit mx-auto my-3 bg-slate-500 p-2  border-slate-200 border-2 hover:bg-slate-600"
              >
                {typeof user?.traders == 'string' &&
                user?.traders?.split(',')?.includes(activetrader?.id)
                  ? 'STOP'
                  : 'START'}
              </button>
              <div className="flex flex-col gap-3">
                <div className="px-10 flex justify-between text-center font-semibold py-5 px-1 odd:bg-slate-400 even:bg-slate-600">
                  <span>Name</span>
                  <span> {activetrader?.name}</span>
                </div>
                <div className="px-10 flex justify-between text-center font-semibold py-5 px-1 odd:bg-slate-400 even:bg-slate-600">
                  <span>Win Rate</span>
                  <span> {activetrader?.wr}%</span>
                </div>
                <div className="px-10 flex justify-between text-center font-semibold py-5 px-1 odd:bg-slate-400 even:bg-slate-600">
                  <span>Profit Share</span>
                  <span> {activetrader?.ps}%</span>
                </div>
              </div>{' '}
            </div>
          )}
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
