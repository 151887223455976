import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
export default function Experts() {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 bg-darl-800">
        <div className="lg:px-20 p-5 text-center">
          <h2 className="text-2xl">Trading Experts</h2>
        </div>
        <div class="grid lg:grid-cols-2 grid-cols-1 gap-3">
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t1.png"
              className="w-[120px]   h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">SatoshiChartsTeam</p>
            <p className="font-semibold font-md">
              We are a group of 5 Professional Traders
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Mixed STYLE: Day, Swing, HODL
            </p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t2.gif"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">PipPhenes</p>
            <p className="font-semibold font-md">
              With decades of trading and market expertise you're in good hands
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Mixed STYLE: Day, Swing, HODL
            </p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t3.jpg"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">Copy Digi Millionaires</p>
            <p className="font-semibold font-md">
              19 years of trading experience as a professional trader
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Manual STYLE: Swing
            </p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t4.png"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">Nikolaos Kost</p>
            <p className="font-semibold font-md">
              Professional trader, founder of ccbeast
            </p>
            <p className="text-sm font-light text-center">METHOD: Manual</p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t5.png"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">CryptoCivil</p>
            <p className="font-semibold font-md">
              Professional Engineer and Crypto Trader
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Manual STYLE: Daytrading
            </p>
          </div>
          <div className="flex flex-col gap-2 justify-center text-center">
            <img
              src="/asset/img/traders/t6.gif"
              className="w-[120px] h-[120px] rounded-full mx-auto"
            />
            <p className="text-lg font-bold">TheCryptics</p>
            <p className="font-semibold font-md">
              Group of traders making decisions which coins to buy.
            </p>
            <p className="text-sm font-light text-center">
              METHOD: Manual STYLE: Swing, DayTrade
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
