import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useEffect, useState } from 'react'
import axios from 'axios'
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaEye,
} from 'react-icons/fa'
export default function Payouts() {
  const [navState, setNavState] = useState('hide')
  const [payouts, setPayouts] = useState([])
  const [p, setP] = useState(1)
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  function getPayouts(p) {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=payouts&p=' + p}`,
      withCredentials: false,
    })
      .then((response) => {
        setPayouts(response.data)
      })
      .catch((error) => console.error(error))
  }
  useEffect(() => {
    getPayouts(p)
  }, [p])
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1">
        <h2 className="text-2xl mx-auto text-center">Payouts</h2>
        <div className="bg-slate-700 py-10 px-2">
          <div className="relative overflow-x-auto w-full mx-2">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Method
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {payouts?.length > 0 &&
                  payouts?.map((t, k) => (
                    <tr className="bg-white dark:bg-gray-800 " key={k}>
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {t.Value}
                      </th>
                      <td className="px-6 py-4">{t.date}</td>
                      <td className="px-6 py-4">
                        <a
                          className="px-6 py-4 flex gap-2"
                          href={
                            'https://www.blockchain.com/explorer/transactions/btc/' +
                            t.hash
                          }
                        >
                          <span className="truncate">{t.hash}</span>
                          <FaEye className="my-auto" />
                        </a>
                      </td>
                      <td className={'px-6 py-4  text-green-400'}>COMPLETED</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* <div className="mx-auto flex justify-center gap-5">
              <FaArrowAltCircleLeft
                onClick={(p) => setP(p - 1)}
                className="my-auto hover:text-slate-300"
              />
              <FaArrowAltCircleRight
                onClick={(p) => setP(p + 1)}
                className="my-auto hover:text-slate-300"
              />
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
