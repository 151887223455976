import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Tabs } from 'flowbite-react'
export default function Tdeposit({
  coins,
  user,
  curData,
  tplans,
  gettPlans,
  logout,
}) {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  const [cusamt, setCusamt] = useState(300)
  useEffect(() => {
    gettPlans()
  }, [])
  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 w-full">
        <div className="flex flex-2 gap-1 px-3 mt-5">
          <Link to="/office/dashboard">Dashboard</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/deposit">Deposit</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/trading/deposit">Trading</Link>
        </div>
        <h2 className="text-2xl mx-auto text-center">DEPOSIT</h2>
        <div className="flex text-slate-100 mx-auto w-full">
          <div className="w-full grid lg:grid-cols-4 grid-cols-1">
            {tplans.map((t) => (
              <div className="flex flex-col gap-1 mx-auto text-center">
                <p className="mx-auto text-center">
                  Back In Stock (Limited Offer)
                </p>
                <div className="px-10 w-full flex flex-col bg-slate-800  py-6 border-2 border-slate-400 justify-center">
                  <div className="flex flex-col py-3 text-center">
                    <span className="text-2xl font-semibold my-2">
                      {' '}
                      {t.plan} Plan
                    </span>
                  </div>
                  <div className="flex justify-center w-full text-3xl font-semibold  border-slate-300 py-3">
                    <span className="text-center my-auto">{user.cur}</span>
                    <span>
                      {t.plan !== 'Custom' ? (
                        Number(t.amount).toPrecision(5)
                      ) : (
                        <input
                          defaultValue={cusamt}
                          onChange={(e) => setCusamt(e.target.value)}
                          className="rounded-sm p-2 w-[50%] bg-transparent border-1 border-slate-100"
                        />
                      )}
                    </span>
                  </div>
                  <Link
                    to={
                      `/office/trading/deposit/new?amount=${
                        t.plan !== 'Custom' ? t.amount : cusamt
                      }&title=` + t.plan
                    }
                    className="p-3 bg-blue-400 text-white"
                  >
                    PURCHASE PLAN
                  </Link>
                  <p className="my-1 py-6">
                    4.89 stars, based on 19418 reviews
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
